<template>
<b-container>
  <div class="my-4"><p><strong >INFORMATION ABOUT US&nbsp;</strong></p>
    <p>© 2024 KPMG LLP, a UK limited liability partnership and a member firm of the KPMG global organisation of independent member firms affiliated with KPMG International Limited, a private English company limited by guarantee. All rights reserved.</p>
    <p>For more detail about the structure of the KPMG global organisation please visit <a href="https://home.kpmg/governance">https://home.kpmg/governance</a></p>
    <p><strong>NO RELIANCE ON INFORMATION</strong></p>
    <p>The content on our site is provided for general information only. It is not intended to amount to advice or serve as a substitute for any audit, advisory, tax or other professional advice, consultation or service.</p>
    <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.&nbsp;</p>
    <p>You acknowledge and agree that no professional relationship of any nature is created solely by the use of the site or through any correspondence or communication with KPMG LLP in relation to such use, nor will our professional relationship or obligations be affected by such use, correspondence or communication.</p>
    <p><strong>TRADEMARKS&nbsp;</strong></p>
    <p>The KPMG name and logo are registered trademarks or trademarks of KPMG International and other products and service names mentioned on the Site may be the registered trademarks or trademarks of KPMG International or KPMG LLP.&nbsp;</p>
    <p>Use of these marks requires express prior permission from, and a license agreement with, KPMG International or KPMG LLP. Unauthorised use of these and any other of KPMG’s portfolio of trademarks is prohibited and may become subject to legal proceedings.&nbsp;</p>
    <p>To request written approval, please use the “<a href="https://home.kpmg/uk/en/home/misc/contact-kpmg.html" target="_blank">Contact us</a>” feature.</p>
    <p><strong>OUR LIABILITY</strong></p>
    <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied, included but not limited to that the site will be uninterrupted, without delay, error-free, omission-free, or free of viruses.&nbsp;</p>
    <p>KPMG, its related partners, managing directors, principals, agents or employees will not be liable to any user for any loss or damage arising under or in connection with but not limited to:&nbsp;</p>
    <ul>
    <li>use of, or inability to use, our site;&nbsp;</li>
    <li>or use of, or reliance on, any content displayed on our site.&nbsp;</li>
    </ul>
    <p>We have no liability to you for any loss of profit, savings, data, sales or revenue, loss of business, business interruption or business opportunity or any indirect, incidental, special, exemplary, punitive, consequential or other loss or damage.&nbsp;</p>
    <p><strong>VIRUSES&nbsp;</strong></p>
    <p>We do not guarantee that our site will be secure or free from bugs or viruses and will not be liable for any loss or damage caused by a virus infected either through our site or third party link.&nbsp;</p>
    <p>You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.&nbsp;</p>
    <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990.&nbsp;</p>
    <p><strong>LINKING TO OUR SITE&nbsp;</strong></p>
    <p>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information and convenience only. We have no control over, and are not responsible for, the contents of those sites or resources.&nbsp;</p>
    </div>
</b-container>
</template>

<script>
export default {
  created: function () {
    document.title = "KPMG Regulatory Horizon: Legal"
    this.$stat.log({ page: 'legal', action: 'open legal' })
  }
}
</script>
